.App {
  height: 100vh;
  background-image: url("/assets/SilverGroup.jpg");
  background-repeat: no-repeat;
  background-position: bottom 10% right -30px;

  .welcome {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-top: 5%;
    text-align: center;
    align-items: center;
    color: #00376b;

    #qrcode {
      border-radius: 25px;
    }
  }

  #userDetail {
    position: absolute;
    right: 0;
    bottom: 0;
    color: rgb(214, 214, 214);
    font-size: x-small;
  }
}

@media only screen and (max-width: 799px) and (orientation: landscape) {
  .App {

    background-size: 45%;

    .welcome {
      margin-top: 2%;
      margin-left: 0 !important;
      padding: 5px;

      #paaslogo {
        width: 50%;
      }

      #qrcode {
        width: 20%;
      }

      #randomImage {
        width: 10%;
      }

      h1,
      h2 {
        font-size: medium;
      }
    }
  }
}

@media only screen and (max-width: 799px) and (orientation: portrait) {
  .App {

    background-size: 100%;

    .welcome {
      margin-top: 5%;
      margin-left: 0 !important;
      padding: 5px;

      #paaslogo {
        width: 50%;
      }

      #qrcode {
        width: 40%;
      }

      #randomImage {
        width: 25%;
      }

      h1,
      h2 {
        font-size: xx-large;
        margin-bottom: unset;
      }
    }
  }
}

@media only screen and (min-width: 800px) and (orientation: landscape) {

  .App {
    background-size: 45%;

    .welcome {
      margin-top: 2%;
      margin-left: 0 !important;

      #paaslogo {
        width: 25%;
      }

      h1,
      h2 {
        font-size: medium;
      }

      #qrcode {
        width: 150px;
      }

      #randomImage {
        width: 10%;
      }

    }

  }
}

@media only screen and (min-width: 800px) and (orientation: portrait) {

  .App {
    background-size: 95%;

    .welcome {
      margin-top: 10%;
      margin-left: 0 !important;

      #paaslogo {
        width: 65%;
      }

      h1,
      h2 {
        font-size: xxx-large;
        margin-bottom: unset;
      }

      #qrcode {
        width: 350px;
      }

      #randomImage {
        width: 15%;
      }

    }

  }
}


@media only screen and (min-width: 1180px) and (orientation: landscape) {

  .App {
    background-size: 70%;

    .welcome {
      margin-top: 5%;
      max-width: 800px;

      #paaslogo {
        width: 80%;
      }

      h1,
      h2 {
        font-size: xx-large;
        margin-left: 3px;
        margin-bottom: unset;
      }

      #qrcode {
        width: 250px;
      }

      #randomImage {
        width: 15%;
      }
    }
  }

}